import React, { useEffect, useState } from "react";
import Speaking from "./Speaking";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../redux/features/question/questions";
import { useLocation, useNavigate } from "react-router-dom";
import FormName from "../../Components/Speaking/FormName/FormName";
import Finishing from "../../Components/Speaking/Finishing/Finishing";
import useStore from "../../Zustand";
import { add } from "../../redux/features/question/user";
import { toast } from "react-toastify";
import { Freeze } from "react-freeze";
import { Dialog, Slide } from "@mui/material";
import { useOptionstore } from "../../redux/Stores";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Page() {
  const [ready, setReady] = useState({ ready: false, speaker: "adam" });

  useEffect(() => {
    console.log(ready);
  }, [ready]);
  return (
    <div>
      <Dialog fullScreen open={!ready.ready} TransitionComponent={Transition}>
        <FormName setReady={setReady} ready={ready} />
      </Dialog>
      <Speaking setReady={setReady} ready={ready} />
    </div>
  );
}
