import React, { useEffect, useRef, useState } from "react";

import { VscDebugStart } from "react-icons/vsc";
import { Divider } from "@mui/material";
import { FaRepeat } from "react-icons/fa6";

import "./Practice.css";
import P5Wave from "../../Components/Speaking/P5Wave/P5Wave";
import { SpeakFunc } from "../../Components/Speaking/speakFunc/speakFunc";
import { toast } from "react-toastify";
import axios from "axios";
import MuiModal from "../../Components/Speaking/MuiModal/MuiModal";
import {
  startRecording,
  stopRecording,
} from "../../Components/Speaking/record/Recording";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useLocation, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import thinkingAnim from "../../assets/thinkingAnim.json";
import clockExamAnim from "../../assets/clockExamAnim.json";
import cloclAnim3 from "../../assets/cloclAnim3.json";

const Practice = React.memo(({ setReady, ready }) => {
  // states
  const [finished, setFinished] = useState(false);
  const [finishAfter, setFinishAfter] = useState(false);
  let [started, setStarted] = useState(false);
  const [questionsCount, setQuestionsCount] = useState(false);
  let [timeLine, setTimeLine] = useState(1);
  let [data, setData] = useState(null);
  let [rerender, setRerender] = useState("");
  let [isWaiting, setIsWaiting] = useState(null);
  let [part, setPart] = useState(1);
  let [urlVal, setUrlVal] = useState(null);
  let [loading, setLoading] = useState(false);
  let [isRecording, setIsRecording] = useState({
    isRecording: false,
    isSupported: true,
    isRejected: false,
  });

  // refs
  let waitingRef = useRef(null);
  let currentRef = useRef(null);
  let thinkingRef = useRef(null);
  let finishRef = useRef(null);

  let [questions_id, setQuestions_id] = useState("");
  // other hooks
  const navigate = useNavigate();

  let REACT_APP_API_CORE = "https://calmness28.jprq.app";

  useEffect(() => {
    if (waitingRef.thinking !== 0) {
      setIsWaiting("thinking");
    }
    if (waitingRef.thinking === 0) {
      setIsWaiting("speaking");
    }
  }, [rerender]);

  // checking oudio recording again for sure
  useEffect(() => {
    if (!isRecording.isSupported) {
      alert(
        "Your browser does not support our programm! \nUpdate your browser!"
      );
      setReady({ ...ready, isReady: false });
    }

    if (isRecording.isRejected) {
      alert(
        "you did not allow us to reacord your voice!  or  there is problem with recording!"
      );
      setReady({ ...ready, isReady: false });
    }

    if (isRecording.isRecording) {
      toast("Recording started!");
    }
  }, [isRecording]);

  useEffect(() => {
    let getData = async () => {
      try {
        setLoading(true);
        let token = localStorage.getItem("token");
        if (!token) {
          navigate("/");
        }

        let { data } = await axios.get(
          REACT_APP_API_CORE + "/api/admin/speaking/practice/part_one",
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        console.log("data");
        console.log(data);
        waitingRef.thinking = 0;
        waitingRef.speaking = 0;

        setQuestionsCount(1);
        let part1_id = ("" + data.question_id)
          .split("")
          .filter((el, i) => {
            if (i !== 2) {
              return el;
            }
          })
          .join("/");

        setQuestions_id(part1_id);

        setData(data);
      } catch (error) {
        console.log("error");
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  let finishAfterThis = () => {
    finishRef.finish = true;
    setFinishAfter(true);
  };

  // start exam
  let handleStart = async () => {
    startRecording(isRecording, setIsRecording);
    setStarted(true);

    console.log(questionsCount);
    setRerender("i");

    setPart(1);

    waitingRef.thinking = 5;
    waitingRef.speaking = 30;
    currentRef.text = data.question_text;
    currentRef.id = data.question_id;
    await SpeakFunc(
      REACT_APP_API_CORE +
        "/api/speech/" +
        ready.speaker +
        "/" +
        data.question_id +
        ".mp3"
    );

    let intevalId = setInterval(() => {
      setRerender("");
      if (waitingRef.thinking !== 0) {
        setRerender(waitingRef.thinking - 1);
        waitingRef.thinking -= 1;
      }

      if (waitingRef.thinking === 0 && waitingRef.speaking !== 0) {
        setRerender(waitingRef.speaking - 1);
        waitingRef.speaking -= 1;
      }
      console.log(waitingRef.speaking);
      console.log(waitingRef.thinking);
      // if (waitingRef.speaking == 0 && waitingRef.thinking == 0) {
      //   clearInterval(intevalId);
      // }
    }, 1000);

    await new Promise((resolve) => {
      setTimeout(() => {
        clearInterval(intevalId);
        resolve();
      }, 1000 * 35);
    });
    stopRecording(setUrlVal);
    setFinished(true);
  };

  return (
    <>
      {" "}
      {loading ? (
        <div className="loading-state">
          <div className="loader" />
        </div>
      ) : (
        <div className="bg-[#EFF6FF]">
          <section className="demo-test flex min-h-screen flex-col items-center  pt-5">
            {/* <div className="container">
              <div className="flex flex-row items-center justify-center">
                <ul className="text-center text-[12px] md:text-[14px] flex flex-row items-center justify-center">
                  <li>Free test /</li>
                  <li className="text-[#00000099] "> (Student) </li>
                </ul>
              </div>
            </div> */}
            <Divider />
            <div className="container ">
              {/* <div className="stapper ">
                <ul className="w-[100%] flex flex-row justify-center">
                  <li className="bg-[#3498db]">1</li>
                  <li className="bg-[#3498db]">2</li>
                  <li className="bg-[#3498db]">3</li>
                </ul>

                <div className="stepper-line">
                  <div
                    style={{
                      width: `${Math.floor(
                        (100 * timeLine) / questionsCount
                      )}%`,
                    }}
                  ></div>
                </div>
              </div> */}

              <div className="card bg-[#1014180a] shadow-3 ">
                <div className="card-header">
                  <h2 className="pb-2 pt-4 bg-[#fff] font-[600] text-xl   leading-tight text-[#6B7280]">
                    Feel free to answer only one question
                  </h2>
                </div>
                <hr className="mb-2 h-0.5 border-t-0 bg-[#3498db] opacity-100 dark:opacity-50" />
                <div className="card-subheader flex-col text-2xl font-medium leading-tight">
                  <h2 className="card-subheader-ttile text-[#111827] font-[600]">
                    Practice test
                  </h2>

                  <div className="pl-[10px]  card-subheader-questions text-bold pt-[8px] md:pt-4 flex flex-col w-full">
                    {part == 2 ? (
                      <p>hello part 2</p>
                    ) : (
                      <p className="text-[16px] font-[500]">
                        {currentRef.text}
                      </p>
                    )}
                  </div>
                </div>
                <div className="card-context min-h-[200px] max-h-[300px]   items-center  ">
                  <div
                    className={
                      " w-[90%] flex flex-col justify-center items-center pt-4 pb-4 pl-[8px] pr-[8px] md:w-[200px] md:pl-[20px] md:pr-[20px]  rounded  "
                      // (isCounting.think ? "bg-[#3498db36] " : "text-[#111827]")
                    }
                  >
                    <div className="w-[80px]  ">
                      {isWaiting == "thinking" ? (
                        <Lottie
                          animationData={thinkingAnim}
                          loop={true}
                          // autoplay={isWaiting == "thinking" ? true : false}
                        />
                      ) : (
                        <Lottie
                          animationData={thinkingAnim}
                          loop={false}
                          autoplay={false}
                          // autoplay={isWaiting == "thinking" ? true : false}
                        />
                      )}
                    </div>

                    <h2 className=" pb-2 leading-tight text-[11px] md:text-[14px] font-[400] ">
                      O&apos;ylash uchun
                    </h2>
                    <h2
                      key={5}
                      className=" text-[11px] font-[600] md:text-[22px] text-[600]  leading-tight relative "
                    >
                      {waitingRef.thinking} Second
                    </h2>
                  </div>
                  <div className=" w-[100%] h-[200px] flex   items-center ">
                    <P5Wave isWaiting={isWaiting} />
                  </div>
                  <div
                    className={
                      "w-[90%] flex flex-col justify-center items-center  pt-4  pb-4  pl-[8px] pr-[8px] md:w-[200px] md:pl-[20px] md:pr-[20px]   rounded  "
                      // (true ? "bg-[#3498db36] " : "text-[#111827]")
                    }
                  >
                    <div className="w-[80px] overflow-hidden">
                      {isWaiting == "speaking" ? (
                        <Lottie
                          // animationData={clockExamAnim}
                          animationData={cloclAnim3}
                          // animationData={clockAnim}
                          loop={true}
                        />
                      ) : (
                        <Lottie
                          // animationData={clockExamAnim}
                          animationData={cloclAnim3}
                          // animationData={clockAnim}

                          autoplay={false}
                          loop={false}
                        />
                      )}
                    </div>
                    <h2 className=" pb-2 text-[11px] md:text-[14px] font-medium leading-tight fs-16">
                      Gapirish uchun
                    </h2>
                    <h2
                      key={30}
                      className=" text-[11px] font-[600] md:text-[22px] font-[600]  leading-tight"
                    >
                      {waitingRef.speaking} Second
                    </h2>
                  </div>
                </div>
                <div className="card-footer bg-[#fff] flex align-center justify-around pt-[18px] md:pt-10  gap-4">
                  <button
                    disabled={started ? false : true}
                    color="#52e8a0"
                    className={`w-auto border-[2px] border-[#52e8a0] text-[14px] rounded  font-[400] flex flex-row gap-[8px] items-center justify-center py-[10px]`}
                    onClick={() => {
                      SpeakFunc(
                        REACT_APP_API_CORE +
                          "/api/speech/" +
                          ready.speaker +
                          "/" +
                          currentRef.id +
                          ".mp3"
                      );
                    }}
                  >
                    Repeat
                  </button>
                  {started ? (
                    <></>
                  ) : (
                    <button
                      disabled={data ? false : true}
                      onClick={handleStart}
                      className={`w-auto border-[2px] border-[#3498db] text-[14px] rounded hover:bg-[#3498db] hover:text-[#fff] font-[400] flex flex-row gap-[8px] items-center justify-center py-[10px] `}
                    >
                      Start
                    </button>
                  )}
                  {/* <button
                    disabled={started ? false : true}
                    color="#52e8a0"
                    onClick={finishAfterThis}
                    className={
                      ` w-auto border-[2px] border-[#52e8a0] text-[14px] rounded hover:bg-[#ffff]  font-[400] flex flex-row gap-[8px] items-center justify-center py-[10px] ` +
                      (finishAfter ? "border-none" : "")
                    }
                  >
                    <CheckCircleIcon />
                    <span>Finish after this</span>
                  </button> */}
                  <MuiModal
                    finished={finished}
                    urlVal={urlVal}
                    questions_id={questions_id}
                    data={data}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
});

export default Practice;
