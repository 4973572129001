import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  current: "",
};

const currentSlice = createSlice({
  name: "current",
  initialState,
  reducers: {
    changeCurrent(state, actions) {
      return {
        current: actions.payload.question,
      };
    },
  },
});

export const { changeCurrent } = currentSlice.actions;

export default currentSlice.reducer;
