import { configureStore } from "@reduxjs/toolkit";
import questionsSlice from "./features/question/questions";
import userReducer from "./features/question/user";
import currentSlice from "./features/question/current";

let configStore = () => {
  return {
    reducer: {
      questions: questionsSlice,
      user: userReducer,
      current: currentSlice,
    },
  };
};

export const store = configureStore(configStore());
