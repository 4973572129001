import React from "react";
import "./P5Wave.css";
import recAnimation from "../../../assets/recAnimation.json";
import Lottie from "lottie-react";
export default function P5Wave({ isWaiting }) {
  return (
    <div className="w-[70%] h-[100px] flex items-center justify-center  overflow-hidden">
      {isWaiting !== "thinking" ? (
        <Lottie
          style={{ width: "100%", haight: "100px" }}
          animationData={recAnimation}
          height={"100px"}
          loop={true}
        />
      ) : (
        <Lottie animationData={recAnimation} loop={false} autoplay={false} />
        // {/* // <div className="circle-not" id="circle1"></div>
        // <div className="circle-not" id="circle2"></div>
        // <div className="circle-not" id="circle3"></div> */}
        // recAnimation
      )}
    </div>
  );
}
