import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "./Dashboard.css";
import { useScroll, useWindowSize } from "react-use";
import Confetti from "react-confetti";
import Heaader from "../../Components/Dashboard/Heaader";
import Footer from "../../Components/Dashboard/Footer";
import aiAnim from "../../assets/aiAnim.json";
import Lottie from "lottie-react";
import AiBot from "../../Components/aiBot/aiBot";

export default function Dashboard() {
  const scrollRef = React.useRef(null);
  const { x, y } = useScroll(scrollRef);
  let [firstTime, setFirstTime] = useState(false);

  let navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    let isFirst = localStorage.getItem("isFirst");
    let token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }

    if (!isFirst) {
      localStorage.setItem("isFirst", "true");
      setFirstTime(true);
    }
  }, []);
  const { width, height } = useWindowSize();
  const [showConfetti, setShowConfetti] = React.useState(false);

  return (
    <div className="relative">
      {firstTime ? (
        <Confetti width={width} height={height} recycle={showConfetti} />
      ) : (
        <></>
      )}
      <div className=" bg-[#f5f6fb]  w-[auto]">
        <Heaader />
        <main ref={scrollRef} className="">
          <Outlet />
        </main>
        <Footer />
      </div>
      {/* <AiBot /> */}
    </div>
  );
}
