import * as React from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import Home from "./Pages/Home/Home";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import NotFound from "./Pages/NotFound/NotFound";
import Page from "./Pages/Speaking/Page";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Analytics } from "@vercel/analytics/react";
import Login from "./Pages/Login/Login";
import Dashboard from "./Pages/Dashboard/Dashboard";
import MainSpeaking from "./Pages/Dashboard/MainSpeaking/MainSpeaking";
import SpeakingPartOne from "./Pages/Speaking/partOne/SpeakingPartOne";
import SpeakingPartOnePage from "./Pages/Speaking/partOne/sepakingPartOnePage";
import PartTwoThreePage from "./Pages/Speaking/PartTwoThree/PartTwoThreePage";
import Practice from "./Pages/Practice/Practice";
import PracticePage from "./Pages/Practice/PracticePage";
import Blog from "./Pages/Blog/Blog";
// MainSpeaking
const router = createBrowserRouter([
  {
    path: "/login/:userId",
    element: <Login />,
  },
  {
    path: "/blog/:id",
    element: <Blog />,
  },
  {
    path: "/",
    element: <Dashboard />,
    children: [
      {
        path: "",
        element: <MainSpeaking />,
      },
    ],
  },
  {
    path: "/speaking/full_part",
    element: <Page />,
  },
  {
    path: "/speaking/part_one",
    element: <SpeakingPartOnePage />,
  },
  {
    path: "/speaking/part_two_three",
    element: <PartTwoThreePage />,
  },
  {
    path: "/practice",
    element: <PracticePage />,
  },
  {
    path: "*", // Wildcard path for unmatched routes
    element: <NotFound />, // Render your 404 page component
  },
]);

createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <ToastContainer />
    <RouterProvider router={router} />
    <Analytics />
  </Provider>
);
