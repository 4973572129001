import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import { Link, useNavigate } from "react-router-dom";
import { SpeakFunc } from "../speakFunc/speakFunc";
import { toast } from "react-toastify";
import { Box, CircularProgress } from "@mui/material";
// import { useSpeechSynthesis } from "react-speech-kit";
import uploadImage from "../../../assets/uploadImage.svg";
import DownloadAnimIcon from "../../../assets/DownloadAnimIcon.json";
import happyBirth from "../../../assets/happyBirth.json";
import music from "../../../assets/music.mp3";
import Lottie from "lottie-react";
import Modal from "react-animated-modal";
import Plyr, { usePlyr } from "plyr-react";
import "plyr-react/plyr.css";
import "./Finishing.css";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { AudioPlayer } from "react-audio-play";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";

export default function Finishing({ urlVal, timeLine, questions_id, data }) {
  let [loading, setLoading] = useState(false);
  let [loaded, setLoaded] = useState(false);
  let [blobUrl, setBlobUrl] = useState(null);
  let [result, setResult] = useState(null);
  // let [timeLine, setTimeLine] = useState(1);
  const navigate = useNavigate();
  const { width, height } = useWindowSize();
  const [showConfetti, setShowConfetti] = useState(false);
  let [openQuests, setOpenQuests] = useState({
    part1: false,
    part2: false,
    part3: false,
  });
  let [audioState, setAudioState] = useState(null);
  let [audioControl, setAudioControl] = useState({
    play: false,
    playTime: 0,
    duration: 0,
  });

  const playerRef = useRef();

  let REACT_APP_API_CORE = "https://calmness28.jprq.app";

  const downloadRef = useRef();
  let upload = async () => {
    try {
      setLoading(true);
      let token = localStorage.getItem("token");
      if (!token) {
        navigate("/");
      }
      const formData = new FormData();
      formData.append("audio", urlVal);
      formData.append("questions", questions_id);
      formData.append("chat_id", "1283821462");

      let { data } = await axios.post(
        REACT_APP_API_CORE + "/api/answer/speaking/telegram",
        formData,

        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
        }
      );
      console.log(data);
    } catch (error) {
      console.log(error);
      // navigate("/notfound");
    } finally {
      setLoading(false);
    }
  };
  let getRecommendation = async () => {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("audio", urlVal);
      formData.append("questions", questions_id);
      formData.append("chat_id", "1283821462");

      let { data } = await axios.post(
        REACT_APP_API_CORE + "/transcribe",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setResult(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    try {
      setBlobUrl(URL.createObjectURL(urlVal));
      console.log(URL.createObjectURL(urlVal));
      upload();
      getRecommendation();
    } catch (error) {
      console.log(error);
    } finally {
    }
  }, []);

  console.log("finished");

  console.log(data);
  const [currentTime, setCurrentTime] = useState(0);

  const [playerReady, setPlayerReady] = useState(false);

  const handlePlayerReady = (e) => {
    console.log(e);
    setAudioState(e);
  };

  useEffect(() => {
    if (playerRef?.current?.plyr?.media?.duration) {
      setPlayerReady(true);
    }
  }, []);

  let audio = null;
  useEffect(() => {
    audio = new Audio(blobUrl ? blobUrl : music);
    // Add an event listener for when the metadata is loaded
    audio.addEventListener("loadedmetadata", function () {
      console.log(audio.duration);
      setAudioControl({
        ...audioControl,
        duration: audio.duration,
      });
      setPlayerReady(true);
    });
    // playerRef.addEventListener("ondurationchange", handlePlayerReady);
    // Start loading the audio file (optional since setting src will automatically start loading)
    audio.load();
  }, [blobUrl]);

  return (
    <div className="w-[100%]   overflow  mx-auto  rounded-md mb-[200px]">
      {playerRef ? (
        <></>
      ) : (
        <div className="loading-state">
          <div className="loader" />
        </div>
      )}
      <div className="w-[100%]  ">
        <h2 className="text-2xl font-bold text-center my-[40px]">
          Here is your results
        </h2>

        {/* DownloadAnimIcon */}

        <div className="flex flex-col items-center justify-center ">
          <div className="plycustom-container max-w-[600px] pb-[14px] rounded-[14px] w-[90%] flex flex-col items-center justify-center">
            {/* <Plyr
              onTimeUpdate={handlePlayerReady}
              ref={(player) => {
                playerRef.current = player;
                // console.log(player);
                // setAudioState(player);
                // console.log("player");
              }}
              source={{
                type: "audio",
                title: "Example title",
                tooltips: {
                  controls: false,
                  seek: false,
                },
                // @ts-ignore
                sidedock: 0,
                controls: 0,

                sources: [
                  {
                    src: blobUrl ? blobUrl : music,
                    // src: "https://calmness28.jprq.app/api/speech/391.mp3",
                    type: "audio/mp3",
                  },
                  //  "https://calmness28.jprq.app/api/speech/391.mp3"
                ],
              }}
            /> */}
            <div className="bg-[#f1f3f4] w-[100%] flex items-center justify-center pt-[20px]">
              <audio
                className=" mb-[16px] w-[100%]"
                src={blobUrl ? blobUrl : music}
                controls
              ></audio>
            </div>
            {data?.part_id ? (
              <ul className="w-[100%]">
                <li
                  className={
                    "hover:bg-[#2222220d] px-[20px] flex justify-between items-center py-[15px] cursor-pointer text-[14px] "
                  }
                >
                  <div className="flex items-center gap-[10px]">
                    <GraphicEqIcon />

                    <p>{data?.question_text}</p>
                  </div>
                  <span className="flex items-center justify-between gap-[10px]">
                    <span className="text-[#00b2ff] text-[14px] font-[500] "></span>
                  </span>
                </li>
              </ul>
            ) : (
              <></>
            )}
            {!data?.part_id ? (
              <>
                {data?.part1 ? (
                  <div className="w-[100%] ">
                    <button
                      onClick={() => {
                        setOpenQuests({
                          ...openQuests,
                          part1: !openQuests.part1,
                        });
                      }}
                      className="w-[100%] border-t-[2px] hover:bg-[#2222220d] border-[#2222220d] py-[14px]  md:py-[20px] text-[14px] md:text-[16px] flex items-center justify-between px-[20px]"
                    >
                      <span>Part 1</span>

                      <span className="flex items-center justify-between gap-[10px]">
                        <span
                          className={
                            openQuests.part1
                              ? "rotate-180 transition"
                              : "rotate-0 transition"
                          }
                        >
                          <KeyboardArrowDownIcon />
                        </span>
                      </span>
                    </button>
                    <Collapse
                      in={openQuests.part1}
                      timeout="auto"
                      unmountOnExit
                    >
                      <ul>
                        {data?.part1?.questions?.map((el, i) => {
                          return (
                            <li
                              key={el.questions_id}
                              className="hover:bg-[#2222220d] px-[20px] flex justify-between items-center gap-[14px] py-[10px] cursor-pointer text-[12px] md:text-[14px]"
                            >
                              <div className="flex items-center gap-[10px]">
                                <GraphicEqIcon />

                                <p>{el.text}</p>
                              </div>
                              <span className="flex items-center justify-between gap-[10px]">
                                <span className="text-[#00b2ff] text-[14px] font-[500] "></span>
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                    </Collapse>
                  </div>
                ) : (
                  <></>
                )}
                {data?.part2?.questions ? (
                  <div className="w-[100%] ">
                    <button
                      onClick={() => {
                        setOpenQuests({
                          ...openQuests,
                          part2: !openQuests.part2,
                        });
                      }}
                      className="w-[100%] border-y-[2px] hover:bg-[#2222220d] border-[#2222220d] py-[14px]  md:py-[20px] text-[14px] md:text-[16px] flex items-center justify-between px-[20px]"
                    >
                      <span>Part 2</span>

                      <span className="flex items-center justify-between gap-[10px]">
                        <span className="text-[#00b2ff] text-[14px] font-[500] ">
                          {/* 00:50 */}
                        </span>
                        <span
                          className={
                            openQuests.part2
                              ? "rotate-180 transition"
                              : "rotate-0 transition"
                          }
                        >
                          <KeyboardArrowDownIcon />
                        </span>
                      </span>
                    </button>
                    <Collapse
                      in={openQuests.part2}
                      timeout="auto"
                      unmountOnExit
                    >
                      <ul>
                        {data.part2.questions.map((el) => {
                          return (
                            <li
                              key={el.questions_id}
                              className="hover:bg-[#2222220d] px-[10px] flex justify-between items-center py-[10px] cursor-pointer text-[14px]"
                            >
                              <div className="flex items-center gap-[10px]">
                                <GraphicEqIcon />

                                <p>{el.text}</p>
                              </div>
                              <span className="flex items-center justify-between gap-[10px]">
                                <span className="text-[#00b2ff] text-[14px] font-[500] ">
                                  {/* {timeLine - data.part1.questions.length > 1
                                    ? "00:00"
                                    : "--:--"} */}
                                </span>
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                    </Collapse>
                  </div>
                ) : (
                  <></>
                )}

                {data?.part3?.length > 0 ? (
                  <div className="w-[100%] ">
                    <button
                      onClick={() => {
                        setOpenQuests({
                          ...openQuests,
                          part3: !openQuests.part3,
                        });
                      }}
                      className="w-[100%] border-b-[2px] hover:bg-[#2222220d] border-[#2222220d] py-[14px]  md:py-[20px] text-[14px] md:text-[16px] flex items-center justify-between px-[20px]"
                    >
                      <span>Part 3</span>

                      <span className="flex items-center justify-between gap-[10px]">
                        <span className="text-[#00b2ff] text-[14px] font-[500] ">
                          {/* 00:50 */}
                        </span>
                        <span
                          className={
                            openQuests.part3
                              ? "rotate-180 transition"
                              : "rotate-0 transition"
                          }
                        >
                          <KeyboardArrowDownIcon />
                        </span>
                      </span>
                    </button>
                    <Collapse
                      in={openQuests.part3}
                      timeout="auto"
                      unmountOnExit
                    >
                      <ul>
                        {data?.part3?.map((el, i) => {
                          return (
                            <li
                              key={el.questions_id}
                              className="hover:bg-[#2222220d] px-[20px] flex justify-between items-center gap-[14px] py-[10px] cursor-pointer text-[12px] md:text-[14px]"
                            >
                              <div className="flex items-center gap-[10px]">
                                <GraphicEqIcon />

                                <p>{el.text}</p>
                              </div>
                              <span className="flex items-center justify-between gap-[10px]">
                                <span className="text-[#00b2ff] text-[14px] font-[500] ">
                                  {/* {timeLine - data.part1.length + 1 > i + 1
                                    ? "00:00"
                                    : "--:--"} */}
                                </span>
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                    </Collapse>
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="max-w-[500px] w-[100%] ">
            {result ? (
              <h2 className="text-2xl font-[600] mb-2 text-center my-[40px]">
                Score:{" "}
                <span className="text-[#f84f39] text-[40px]">
                  {result.Score}
                </span>{" "}
                out of <span className="text-[#f84f39] text-[40px]">100</span>
              </h2>
            ) : (
              <></>
            )}{" "}
            {result ? (
              <p className="text-left text-[18px] font-[600] text-[#1e293bb3]  my-[10px]">
                {result.consultation}
              </p>
            ) : (
              <div className="relative mt-[100px]">
                <div className="loading-state_abs bg-transparent  flex flex-col gap-[16px]">
                  <p className="font-[500] text-[14px]">
                    Ai Analysing your results
                  </p>
                  <div className="loader" />
                </div>
              </div>
            )}
            {/* <AnimatedModal /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

// let AnimatedModal = () => {
//   let [showModal, setShowModal] = useState(false);
//   return (
//     <div>
//       <Modal
//         visible={true}
//         closemodal={() => setShowModal(true)}
//         type="flipInX"
//       >
//         Some text or JSX inside modal goes here...
//       </Modal>
//       <div onClick={() => setShowModal(true)}>Open Modal</div>
//     </div>
//   );
// };
