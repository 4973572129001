import React, { useEffect, useState } from "react";
import ArticlesCard from "../Articles/ArticlesCard";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function Posts() {
  let [loading, setLoading] = useState(false);
  let [data, setData] = useState(false);

  let navigate = useNavigate();
  const getArticles = async (type) => {
    try {
      if (type.length === 0) {
        console.log("type is required");
        return;
      }
      setLoading(true);
      let { data } = await axios.get(
        "https://calmness28.jprq.app/api/admin/blog?&type=" + type
      );

      console.log(data);
      if (data.success) {
        setLoading(false);
        setData(data);
        // navigate("/posts");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getArticles("publish");
  }, []);

  let formattedate = (created_at) => {
    let date = new Date(created_at);
    let readyDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    });
    let readyTime = date.toLocaleDateString("en-US", {
      hour: "numeric",
      hour12: true,
      minute: "2-digit",
    });
    console.log(`${readyDate} at ${readyTime}`);
    return `${readyDate} at ${readyTime}`;
  };
  return (
    <div className="py-[40px] flex  flex-row items-center justify-center gap-[24px] justify-start flex-wrap">
      {loading ? (
        <div className="loading-abs z-0">
          <div className="flex flex-col gap-[14px] pt-[18px]  items-center justify-center">
            {/* <p className="font-[600]">...</p> */}
            <div className="loader" />
          </div>
        </div>
      ) : (
        <></>
      )}
      {data?.posts ? (
        data.posts.map((el) => {
          let created_at = formattedate(el.created_at);
          return (
            <ArticlesCard
              id={el.slug}
              title={el.title}
              desc={el.description}
              time={created_at}
              image_url={el?.image_url}
            />
          );
        })
      ) : (
        <></>
      )}
    </div>
  );
}
