import React from "react";
// import heroVideo from "../../../assets/heroVideo.mp4";
import dashboardSpeakingAnim from "../../../assets/dashboardSpeakingAnim.json";
import Lottie from "lottie-react";
import { Link, useNavigate } from "react-router-dom";
import SpeakerPhoneOutlinedIcon from "@mui/icons-material/SpeakerPhoneOutlined";
// import component 👇
import Drawer from "react-modern-drawer";
import Posts from "../../../Components/Dashboard/Posts";
//import styles 👇
import "react-modern-drawer/dist/index.css";
import useValidationStore from "../../../redux/ValidationStore";
import ArticlesCardLoading from "../../../Components/Articles/ArticlesCardLoading";

export default function MainSpeaking() {
  const { isValid, setIsValid } = useValidationStore();
  return (
    <section className="pb-[100px]">
      <div className="max-w-[1128px] m-auto w-[98%]  ">
        <div className="relative flex  items-center   lg:pt-[34px]  px-[0px] lg:px-[0px] sm:gap-[20px] sm:pt-[50px] pt-[16px] ">
          <h1 className="text-[#26253b] tracking-[0.72px]  text-left font-[600] mb-0 leading-[36px] text-[30px] sm:text-[50px]  lg:text-[72px]">
            <span className="block leading-[100%]">
              Participate in Mock test
            </span>
            <span className="block leading-[100%]">
              and improve your skills
            </span>
          </h1>
        </div>
        {/* max-w-[1000px] */}
        <div className="w-[100%] py-[10px] flex flex-nowrap gap-[4px] md:gap-[12px] text-[14px]  overflow-x-scroll overflow-y-visible  px-[8px] lg:pt-[34px] pt-[24px] text-[12px] md:text-[14px]">
          <Link
            to={
              isValid ? "/speaking/full_part" : "https://t.me/creators_team_bot"
            }
            className="shrink-0  px-[24px] py-[12px] bg-[#fff] rounded-3xl flex gap-[6px] items-center text-[#00] cursor-pointer font-[500]  hover:scale-105 transition"
          >
            <span className="relative flex h-3 w-3">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
            </span>{" "}
            Speaking mock
          </Link>
          <Link
            to={
              isValid ? "/speaking/part_one" : "https://t.me/creators_team_bot"
            }
            className="shrink-0  px-[24px] py-[12px] bg-[#fff] rounded-3xl flex gap-[6px] items-center text-[#00] cursor-pointer font-[500]  hover:scale-105 transition"
          >
            <SpeakerPhoneOutlinedIcon /> Part One
          </Link>
          <Link
            to={
              isValid
                ? "/speaking/part_two_three"
                : "https://t.me/creators_team_bot"
            }
            className="shrink-0 px-[24px] py-[12px] bg-[#fff] rounded-3xl flex gap-[6px] items-center text-[#00] cursor-pointer font-[500]  hover:scale-105 transition"
          >
            <SpeakerPhoneOutlinedIcon /> Part Two & three
          </Link>
          <Link
            to={isValid ? "/practice" : "https://t.me/creators_team_bot"}
            className="shrink-0 px-[24px] py-[12px] bg-[#fff] rounded-3xl flex gap-[6px] items-center text-[#00] cursor-pointer font-[500]  hover:scale-105 transition"
          >
            <SpeakerPhoneOutlinedIcon /> Practice
          </Link>
        </div>
        {/* <ArticlesCardLoading /> */}
        <Posts />
      </div>
    </section>
  );
}

// choose sections
let DrawerRop = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [option, setOption] = React.useState("");

  let navigate = useNavigate();

  const toggleDrawer = () => {
    // burgerRef.current.play();
    setIsOpen((prevState) => !prevState);
  };
  return (
    <>
      <button
        onClick={toggleDrawer}
        // onClick={() => {
        //   navigate("/speaking");
        // }}
        className="text-[18px] max-w-[400px] uppercase transition-colors w-[80%] h-[50px] border-[1px] border-[#f84f39] hover:bg-[#fff] hover:text-[#000] font-light"
      >
        Testni boshlash
      </button>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="bottom"
        className="  pt-[20px] bg-transparent"
      >
        <div className="flex flex-col items-center justify-center gap-[10px]">
          <div>
            <p>Choose one of these options:</p>
          </div>

          <button
            onClick={() => {
              navigate("/speaking/part_one");
            }}
            className={
              "relative  flex  sm:flex-row flex-col items-center border-2 p-3 mb-1 border rounded-xl cursor-pointer select-none justify-between overflow-hidden  bg-coolGray-100 border-gray-100 hover:bg-coolGray-200  " +
              (option === "partone" ? "bg-coolGray-200 border-gray-600" : "")
            }
          >
            Part one only
          </button>
          <button
            onClick={() => {
              navigate("/speaking/part_two_three");
            }}
            className={
              "relative  flex  sm:flex-row flex-col items-center border-2 p-3 mb-1 border rounded-xl cursor-pointer select-none justify-between overflow-hidden  bg-coolGray-100 border-gray-100 hover:bg-coolGray-200  " +
              (option === "part_two_three"
                ? "bg-coolGray-200 border-gray-600"
                : "")
            }
          >
            Part two & Part three only
          </button>
          <button
            onClick={() => {
              navigate("/speaking/full_part");
            }}
            className={
              "relative  flex  sm:flex-row flex-col items-center border-2 p-3 mb-1 border rounded-xl cursor-pointer select-none justify-between overflow-hidden  bg-coolGray-100 border-gray-100 hover:bg-coolGray-200  " +
              (option === "partfull" ? "bg-coolGray-200 border-gray-600" : "")
            }
          >
            Full test
          </button>
        </div>
      </Drawer>
    </>
  );
};
