import { toast } from "react-toastify";
import Recorder from "recorder-js";
import MicRecorder from "mic-recorder-to-mp3";

// Создаем экземпляр MicRecorder
const recorder = new MicRecorder({ bitRate: 128 });

export const startRecording = (isRecording, setIsRecording) => {
  try {
    // Проверяем поддержку API
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.error("Браузер не поддерживает запись аудио");
      setIsRecording({
        ...isRecording,
        isSupported: false,
      });
      return;
    }

    // Запрашиваем доступ к микрофону
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        // Начинаем запись
        recorder
          .start(stream)
          .then(() => {
            console.log("Запись началась");
            setIsRecording({
              ...isRecording,
              isRecording: true,
            });

            // setIsRecording(true); // Раскомментируйте, если нужно обновить состояние
          })
          .catch((err) => {
            setIsRecording({
              ...isRecording,
              isRejected: true,
            });
            console.error("Ошибка при начале записи: ", err);
          });
      })
      .catch((err) => {
        setIsRecording({
          ...isRecording,
          isRejected: true,
        });
        console.error("Нет доступа к микрофону: ", err);
      });
  } catch (error) {
    console.log(error);
  }
};

export const stopRecording = (setUrlVal) => {
  try {
    recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const file = new File(buffer, "me-at-thevoice.mp3", {
          type: blob.type,
          lastModified: Date.now(),
        });
        console.log(file);
        setUrlVal(file);
      })
      .catch((e) => {
        alert(
          "error is " +
            e.message +
            "  + \nPlease contact us and say what error occured!"
        );

        console.error(e);
      });
  } catch (error) {
    console.log(error);
  }
};
