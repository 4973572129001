import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../../redux/features/question/questions";
import { useLocation, useNavigate } from "react-router-dom";
import FormName from "../../../Components/Speaking/FormName/FormName";
import Finishing from "../../../Components/Speaking/Finishing/Finishing";
import useStore from "../../../Zustand";
import { add } from "../../../redux/features/question/user";
import { toast } from "react-toastify";
import { Freeze } from "react-freeze";
import { Dialog, Slide } from "@mui/material";
import PartTwoThree from "./PartTwoThree";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PartTwoThreePage() {
  let [ready, setReady] = useState({
    speaker: "adam",
    ready: false,
  });
  // setReady, ready
  // let uuid = location.pathname.split("/")[2];
  useEffect(() => {
    console.log(ready);
  }, [ready]);

  return (
    <div>
      <Dialog fullScreen open={!ready.ready} TransitionComponent={Transition}>
        <FormName ready={ready} setReady={setReady} />
      </Dialog>
      <PartTwoThree ready={ready} setReady={setReady} />
    </div>
  );
}
