import React from "react";
import page_404 from "../../assets/404_page.jpg";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import Anim404 from "../../assets/404Anim.json";
export default function NotFound() {
  return (
    <div className="container flex flex-col items-center justify-center">
      <div className="w-[60%] mt-10 flex flex-row items-center justify-center">
        <Lottie animationData={Anim404} loop={true} />
        {/* 404Anim */}
      </div>
      <div className="max-w-[300px] flex flex-col items-center">
        <span
          className="block font-serif text-xl font-normal text-black mb-2"
          role="none"
        >
          OOPS...
        </span>
        <span
          className="block font-serif text-xl font-normal text-black mb-2"
          role="none"
        >
          Something went wrong
        </span>
        <Link
          to={"/"}
          className=" w-[200px] flex items-center gap-[10px] mt-[30px] btn btn-primary bg-[#312b41] text-[#fff] py-3 px-4 rounded-xl btn-xl btn-normal"
        >
          Back To Home Page
        </Link>
      </div>
    </div>
  );
}
