import axios from "axios";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function Login({ params }) {
  let location = useLocation();
  let naivate = useNavigate();
  console.log(params);
  let id = location.pathname.split("/")[2];
  console.log(id);
  let token = params;
  let REACT_APP_API_CORE = "https://calmness28.jprq.app";
  useEffect(() => {
    let getAuthed = async () => {
      try {
        let { data } = await axios.post(
          REACT_APP_API_CORE + "/login?token=" + id
        );
        console.log(data);
        if (data.new_token_data.name) {
          localStorage.setItem("name", data.new_token_data.name);
          localStorage.setItem("token", data.new_token_data.token);
          naivate("/");
        }
      } catch (error) {
        console.log(error);
      } finally {
      }
    };
    getAuthed();
  }, []);
  return (
    <div className="loading-state">
      <div className="flex flex-col gap-[20px] items-center justify-center">
        <p>Authing...</p>
        <div className="loader" />
      </div>
    </div>
  );
}
