import React, { useEffect, useRef, useState } from "react";

import { VscDebugStart } from "react-icons/vsc";
import { Divider } from "@mui/material";
import { FaRepeat } from "react-icons/fa6";

import "./Speaking.css";
import P5Wave from "../../Components/Speaking/P5Wave/P5Wave";

import { toast } from "react-toastify";
import axios from "axios";
import MuiModal from "../../Components/Speaking/MuiModal/MuiModal";
import {
  startRecording,
  stopRecording,
} from "../../Components/Speaking/record/Recording";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useLocation, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import thinkingAnim from "../../assets/thinkingAnim.json";
import clockExamAnim from "../../assets/clockExamAnim.json";
import cloclAnim3 from "../../assets/cloclAnim3.json";
import music from "../../assets/music.mp3";
import { useOptionstore } from "../../redux/Stores";
import { SpeakFunc } from "../../Components/Speaking/speakFunc/speakFunc";

const Speaking = React.memo(({ setReady, ready }) => {
  // states
  const [finished, setFinished] = useState(false);
  const [finishAfter, setFinishAfter] = useState(false);
  let [started, setStarted] = useState(false);
  const [questionsCount, setQuestionsCount] = useState(false);
  let [timeLine, setTimeLine] = useState(1);
  let [data, setData] = useState(null);
  let [rerender, setRerender] = useState("");
  let [isWaiting, setIsWaiting] = useState(null);
  let [part, setPart] = useState("");
  let [urlVal, setUrlVal] = useState(null);
  let [loading, setLoading] = useState(false);
  let [isRecording, setIsRecording] = useState({
    isRecording: false,
    isSupported: true,
    isRejected: false,
  });
  let [questions_id, setQuestions_id] = useState("");
  // refs
  let waitingRef = useRef(null);
  let currentRef = useRef(null);
  let thinkingRef = useRef(null);
  let finishRef = useRef(null);

  // other hooks
  const navigate = useNavigate();
  const [options, setOptions] = useState({ ...ready });

  useEffect(() => {
    if (waitingRef.thinking !== 0) {
      setIsWaiting("thinking");
    }
    if (waitingRef.thinking === 0) {
      setIsWaiting("speaking");
    }
  }, [rerender]);

  // checking oudio recording again for sure
  useEffect(() => {
    if (!isRecording.isSupported) {
      alert(
        "Your browser does not support our programm! \nUpdate your browser!"
      );
      setOptions({ ...options, isReady: false });
    }

    if (isRecording.isRejected) {
      alert(
        "you did not allow us to reacord your voice!  or  there is problem with recording!"
      );
      setOptions({ ...options, isReady: false });
    }

    if (isRecording.isRecording) {
      toast("Recording started!");
    }
  }, [isRecording]);

  let REACT_APP_API_CORE = "https://calmness28.jprq.app";

  useEffect(() => {
    let getData = async () => {
      try {
        setLoading(true);
        let token = localStorage.getItem("token");
        if (!token) {
          navigate("/");
        }
        let { data } = await axios.get(
          REACT_APP_API_CORE + "/api/admin/speaking/history/full_part",

          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        console.log("data");
        console.log(data);
        waitingRef.thinking = 0;
        waitingRef.speaking = 0;
        setQuestionsCount(
          data.part_1.questions.length +
            data.part_2.questions.length +
            data.part_3.length
        );

        let part1_id = ("" + data.part_1.questions[0].question_id)
          .split("")
          .filter((el, i) => {
            console.log(i);
            if (i !== 2) {
              return el;
            }
          })
          .join("/");
        let part2_id = ("" + data.part_2.questions[0].question_id)
          .split("")
          .filter((el, i) => {
            console.log(i);
            if (i !== 2) {
              return el;
            }
          })
          .join("/");
        let part3_id = ("" + data.part_3[0].question_id)
          .split("")
          .filter((el, i) => {
            console.log(i);
            if (i !== 2) {
              return el;
            }
          })
          .join("/");

        // questions_id = part1_id + "*" + part2_id + "*" + part3_id;
        setQuestions_id(part1_id + "*" + part2_id + "*" + part3_id);
        // console.log(questions_id);
        setData(data);
      } catch (error) {
        console.log("error");
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  let conrolRef = useRef(null);
  let timerInterval;
  let currentTime = 1;
  let intevalId;

  // let SpeakFunc = async (id) => {
  //   try {
  //     return await new Promise((resolve, reject) => {
  //       SpeakAudio.current.pause();
  //       SpeakAudio.current.currentTime = 0;

  //       SpeakAudio.current.src =
  //         REACT_APP_API_CORE +
  //         "/api/speech/" +
  //         options.speaker +
  //         "/" +
  //         id +
  //         ".mp3";

  //       SpeakAudio.current.onended = resolve;
  //       SpeakAudio.current.onerror = reject;

  //       SpeakAudio.current.play();
  //     });
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };

  let finishAfterThis = () => {
    finishRef.finish = true;
    stopRecording(setUrlVal);
    setFinished(true);
    setFinishAfter(true);
  };

  // let SpeakAudio = useRef(new Audio());
  // let SkipThis = () => {
  //   // Stop any ongoing playback and reset the src
  //   clearInterval(intevalId);
  //   SpeakAudio.current.pause();
  //   SpeakAudio.current.currentTime = 0;
  //   conrolRef.current = "skip";
  // };

  // let waitingFunc = async (part3, part1, currentTime) => {
  //   let i = currentTime;

  //   // part 1
  //   setRerender(i);
  //   setTimeLine(i);

  //   if (i <= data.part_1.questions.length) {
  //     console.log("part 1");
  //     console.log(i);
  //     setPart(1);

  //     setRerender(data.part_1.questions[i - 1].text);
  //     waitingRef.thinking = data.part_1.questions[i - 1].thinking_time;
  //     waitingRef.speaking = data.part_1.questions[i - 1].speaking_time;
  //     currentRef.text = data.part_1.questions[i - 1].text;
  //     currentRef.id = data.part_1.questions[i - 1].question_id;
  //     console.log(data.part_1.questions[i - 1]);
  //     console.log("data.part_1.questions[i - 1]");

  //     await SpeakFunc(data.part_1.questions[i - 1].question_id);

  //     intevalId = setInterval(() => {
  //       setRerender("");
  //       if (waitingRef.thinking !== 0) {
  //         setRerender(waitingRef.thinking - 1);
  //         waitingRef.thinking -= 1;
  //       }

  //       if (waitingRef.thinking === 0 && waitingRef.speaking !== 0) {
  //         setRerender(waitingRef.speaking - 1);
  //         waitingRef.speaking -= 1;
  //       }
  //     }, 1000);

  //     await new Promise((resolve) => {
  //       setTimeout(() => {
  //         clearInterval(intevalId);
  //         resolve();
  //       }, 1000 * (data.part_1.questions[i - 1].speaking_time + data.part_1.questions[i - 1].thinking_time));
  //     });
  //   }
  //   // part 2
  //   if (
  //     i > data.part_1.questions.length &&
  //     i === data.part_1.questions.length + 1
  //   ) {
  //     console.log("part2");
  //     setPart(2);
  //     currentRef.text = data.part_2.questions.reduce((acc, curr) => {
  //       return acc + "##" + curr.text;
  //     }, "");
  //     currentRef.ids = [...data.part_2.questions];
  //     waitingRef.thinking = data.part_2.thinking_time;
  //     waitingRef.speaking = data.part_2.speaking_time;
  //     for (let index = 0; index < data.part_2.questions.length; index++) {
  //       await SpeakFunc(data.part_2.questions[index].question_id);

  //       await new Promise(async (resolve) => {
  //         setTimeout(() => {
  //           resolve();
  //         }, 100);
  //       });
  //     }

  //     intevalId = setInterval(() => {
  //       setRerender("");
  //       if (waitingRef.thinking !== 0) {
  //         setRerender(waitingRef.thinking - 1);
  //         waitingRef.thinking -= 1;
  //       }

  //       if (waitingRef.thinking === 0 && waitingRef.speaking !== 0) {
  //         waitingRef.speaking -= 1;
  //         setRerender(waitingRef.speaking - 1);
  //       }
  //     }, 1000);

  //     await new Promise((resolve) => {
  //       setTimeout(() => {
  //         clearInterval(intevalId);
  //         resolve();
  //       }, 1000 * (data.part_1.speaking_time + data.part_2.thinking_time));
  //     });
  //   }
  //   // part 3
  //   if (
  //     i > data.part_1.questions.length + 1 &&
  //     i <= data.part_1.questions.length + 1 + data.part_3.length
  //   ) {
  //     console.log("// part 3");
  //     setPart(3);

  //     currentRef.text = data.part_3[part3 - 1].text;
  //     currentRef.id = data.part_3[part3 - 1].question_id;
  //     waitingRef.thinking = data.part_3[part3 - 1].thinking_time;
  //     waitingRef.speaking = data.part_3[part3 - 1].speaking_time;

  //     await SpeakFunc(data.part_3[part3 - 1].question_id);

  //     intevalId = setInterval(() => {
  //       setRerender("");
  //       if (waitingRef.thinking !== 0) {
  //         setRerender(waitingRef.thinking - 1);
  //         waitingRef.thinking -= 1;
  //       }

  //       if (waitingRef.thinking === 0 && waitingRef.speaking !== 0) {
  //         waitingRef.speaking -= 1;
  //         setRerender(waitingRef.speaking - 1);
  //       }
  //     }, 1000);

  //     await new Promise((resolve) => {
  //       setTimeout(() => {
  //         clearInterval(intevalId);
  //         resolve();
  //       }, 1000 * (data.part_3[part3 - 1].speaking_time + data.part_3[part3 - 1].thinking_time));
  //     });
  //     part3 += 1;

  //     // if (i <= data.part_1.questions.length + 1 + data.part_3.length) {
  //     // }
  //   }
  //   conrolRef.current = "skip";
  // };

  // // start exam
  // let handleStart = async () => {
  //   startRecording(isRecording, setIsRecording);
  //   conrolRef.current = "wait";
  //   if (timerInterval) clearInterval(timerInterval);
  //   setStarted(true);
  //   let part2 = 1;
  //   let part3 = 1;
  //   currentTime = 1;
  //   // SpeakFunc(music);
  //   console.log(questionsCount);
  //   timerInterval = setInterval(async () => {
  //     if (conrolRef.current == "skip") {
  //       currentTime += 1;
  //       conrolRef.current = "wait";
  //     }
  //     if (currentTime.current == "finish") {
  //       stopRecording(setUrlVal);
  //       setFinished(true);
  //       return;
  //     }
  //     if (conrolRef.current == "wait") {
  //       conrolRef.current = "";
  //       await waitingFunc(part3, part2, currentTime);
  //     }
  //   }, 1000);
  // };

  let handleStart = async () => {
    startRecording(isRecording, setIsRecording);
    setStarted(true);
    let part2 = 1;
    let part3 = 1;
    // SpeakFunc(music);
    console.log(questionsCount);
    for (let i = 1; i <= questionsCount; i++) {
      //  setFinished(true);
      // part 1
      setRerender(i);
      setTimeLine(i);
      if (finishRef.finish) {
        return;
      }
      if (i <= data.part_1.questions.length) {
        setPart(1);
        console.log(
          data.part_1.questions[i - 1].speaking_time +
            data.part_1.questions[i - 1].thinking_time
        );
        setRerender(data.part_1.questions[i - 1].text);
        waitingRef.thinking = data.part_1.questions[i - 1].thinking_time;
        waitingRef.speaking = data.part_1.questions[i - 1].speaking_time;
        currentRef.text = data.part_1.questions[i - 1].text;
        currentRef.id = data.part_1.questions[i - 1].question_id;

        await SpeakFunc(
          REACT_APP_API_CORE +
            "/api/speech/" +
            ready.speaker +
            "/" +
            data.part_1.questions[i - 1].question_id +
            ".mp3"
        );

        let intevalId = setInterval(() => {
          setRerender("");
          if (waitingRef.thinking !== 0) {
            setRerender(waitingRef.thinking - 1);
            waitingRef.thinking -= 1;
          }

          if (waitingRef.thinking === 0 && waitingRef.speaking !== 0) {
            setRerender(waitingRef.speaking - 1);
            waitingRef.speaking -= 1;
          }
        }, 1000);

        await new Promise((resolve) => {
          setTimeout(() => {
            clearInterval(intevalId);
            resolve();
          }, 1000 * (data.part_1.questions[i - 1].speaking_time + data.part_1.questions[i - 1].thinking_time));
        });
      }
      // part 2
      if (
        i > data.part_1.questions.length &&
        i === data.part_1.questions.length + 1
      ) {
        console.log("part2");
        setPart(2);
        currentRef.text = data.part_2.questions.reduce((acc, curr) => {
          return acc + "##" + curr.text;
        }, "");
        currentRef.ids = [...data.part_2.questions];
        waitingRef.thinking = data.part_2.thinking_time;
        waitingRef.speaking = data.part_2.speaking_time;

        for (let index = 0; index < data.part_2.questions.length; index++) {
          if (index == 1) {
            await SpeakFunc(
              REACT_APP_API_CORE + "/api/speech/" + ready.speaker + "/say.mp3"
            );
          }
          console.log(index);
          await SpeakFunc(
            REACT_APP_API_CORE +
              "/api/speech/" +
              ready.speaker +
              "/" +
              data.part_2.questions[index].question_id +
              ".mp3"
          );

          await new Promise((resolve) => {
            setTimeout(() => {
              resolve();
            }, 100);
          });
        }

        let intevalId = setInterval(() => {
          setRerender("");
          if (waitingRef.thinking !== 0) {
            setRerender(waitingRef.thinking - 1);
            waitingRef.thinking -= 1;
          }

          if (waitingRef.thinking === 0 && waitingRef.speaking !== 0) {
            waitingRef.speaking -= 1;
            setRerender(waitingRef.speaking - 1);
          }
        }, 1000);

        await new Promise((resolve) => {
          setTimeout(() => {
            clearInterval(intevalId);
            resolve();
          }, 1000 * (data.part_1.speaking_time + data.part_2.thinking_time));
        });
      }
      // part 3
      if (
        i > data.part_1.questions.length + 1 &&
        i <= data.part_1.questions.length + 1 + data.part_3.length
      ) {
        console.log("// part 3");
        setPart(3);

        currentRef.text = data.part_3[part3 - 1].text;
        currentRef.id = data.part_3[part3 - 1].question_id;
        waitingRef.thinking = data.part_3[part3 - 1].thinking_time;
        waitingRef.speaking = data.part_3[part3 - 1].speaking_time;
        await SpeakFunc(
          REACT_APP_API_CORE +
            "/api/speech/" +
            ready.speaker +
            "/" +
            data.part_3[part3 - 1].question_id +
            ".mp3"
        );

        let intevalId = setInterval(() => {
          setRerender("");
          if (waitingRef.thinking !== 0) {
            setRerender(waitingRef.thinking - 1);
            waitingRef.thinking -= 1;
          }

          if (waitingRef.thinking === 0 && waitingRef.speaking !== 0) {
            waitingRef.speaking -= 1;
            setRerender(waitingRef.speaking - 1);
          }
        }, 1000);

        await new Promise((resolve) => {
          setTimeout(() => {
            clearInterval(intevalId);
            resolve();
          }, 1000 * (data.part_3[part3 - 1].speaking_time + data.part_3[part3 - 1].thinking_time));
        });
        part3 += 1;
      }
    }
    stopRecording(setUrlVal);
    setFinished(true);
  };
  // new cnahges
  return (
    <>
      {" "}
      {loading ? (
        <div className="loading-state">
          <div className="loader" />
        </div>
      ) : (
        <div className="bg-[#EFF6FF]">
          <section className="demo-test flex min-h-screen flex-col items-center  pt-5">
            {/* <div className="container">
              <div className="flex flex-row items-center justify-center">
                <ul className="text-center text-[12px] md:text-[14px] flex flex-row items-center justify-center">
                  <li>Free test /</li>
                  <li className="text-[#00000099] "> (Student) </li>
                </ul>
              </div>
            </div> */}
            <Divider />
            <div className="container ">
              <div className="stapper ">
                <ul>
                  <li className="bg-[#3498db]">1</li>
                  <li className="bg-[#3498db]">2</li>
                  <li className="bg-[#3498db]">3</li>
                </ul>

                <div className="stepper-line">
                  <div
                    style={{
                      width: `${Math.floor(
                        (100 * timeLine) / questionsCount
                      )}%`,
                    }}
                  ></div>
                </div>
              </div>

              <div className="card bg-[#1014180a] shadow-3 ">
                <div className="card-header">
                  <h2 className="pb-2 pt-4 bg-[#fff] font-[600] text-xl   leading-tight text-[#6B7280]">
                    Part-{part}
                  </h2>
                </div>
                <hr className="mb-2 h-0.5 border-t-0 bg-[#3498db] opacity-100 dark:opacity-50" />
                <div className="card-subheader flex-col text-2xl font-medium leading-tight">
                  <h2 className="card-subheader-ttile text-[#111827] font-[600]">
                    QUESTION - {timeLine}
                  </h2>

                  <div className="pl-[10px]  card-subheader-questions text-bold pt-[8px] md:pt-4 flex flex-col w-full">
                    {part == 2 ? (
                      <p>
                        {currentRef.text.split("##").map((el, i) => {
                          if (i === 1) {
                            return (
                              <p className="text-[13px] md:text-[17px] font-[600]">
                                {el}
                              </p>
                            );
                          }
                          if (i === 2) {
                            return (
                              <>
                                <p className="my-[6px] md:my-[10px] pl-[20px] text-[14px] md:text-[16px] text-[#00000099] font-[600]">
                                  You should say:
                                </p>
                                <p className="pl-[18px] text-[12px] md:text-[16px] font-[500]">
                                  {el}
                                </p>
                              </>
                            );
                          }
                          return (
                            <>
                              <p className="pl-[18px] text-[12px] md:text-[16px] font-[500]">
                                {el}
                              </p>
                            </>
                          );
                        })}
                      </p>
                    ) : (
                      <p className="text-[16px] font-[500]">
                        {currentRef.text}
                      </p>
                    )}
                  </div>
                </div>
                <div className="card-context min-h-[200px] max-h-[300px]   items-center  ">
                  <div
                    className={
                      " w-[90%] flex flex-col justify-center items-center pt-4 pb-4 pl-[8px] pr-[8px] md:w-[200px] md:pl-[20px] md:pr-[20px]  rounded  "
                      // (isCounting.think ? "bg-[#3498db36] " : "text-[#111827]")
                    }
                  >
                    <div className="w-[80px]  ">
                      {isWaiting == "thinking" ? (
                        <Lottie
                          animationData={thinkingAnim}
                          loop={true}
                          // autoplay={isWaiting == "thinking" ? true : false}
                        />
                      ) : (
                        <Lottie
                          animationData={thinkingAnim}
                          loop={false}
                          autoplay={false}
                          // autoplay={isWaiting == "thinking" ? true : false}
                        />
                      )}
                    </div>

                    <h2 className=" pb-2 leading-tight text-[11px] md:text-[14px] font-[400] ">
                      O&apos;ylash uchun
                    </h2>
                    <h2
                      key={5}
                      className=" text-[11px] font-[600] md:text-[22px] text-[600]  leading-tight relative "
                    >
                      {waitingRef.thinking} Second
                    </h2>
                  </div>
                  <div className=" w-[100%] h-[200px] flex   items-center justify-center ">
                    <P5Wave isWaiting={isWaiting} />
                  </div>
                  <div
                    className={
                      "w-[90%] flex flex-col justify-center items-center  pt-4  pb-4  pl-[8px] pr-[8px] md:w-[200px] md:pl-[20px] md:pr-[20px]   rounded  "
                      // (true ? "bg-[#3498db36] " : "text-[#111827]")
                    }
                  >
                    <div className="w-[80px] overflow-hidden">
                      {isWaiting == "speaking" ? (
                        <Lottie
                          // animationData={clockExamAnim}
                          animationData={cloclAnim3}
                          // animationData={clockAnim}
                          loop={true}
                        />
                      ) : (
                        <Lottie
                          // animationData={clockExamAnim}
                          animationData={cloclAnim3}
                          // animationData={clockAnim}

                          autoplay={false}
                          loop={false}
                        />
                      )}
                    </div>
                    <h2 className=" pb-2 text-[11px] md:text-[14px] font-medium leading-tight fs-16">
                      Gapirish uchun
                    </h2>
                    <h2
                      key={30}
                      className=" text-[11px] font-[600] md:text-[22px] font-[600]  leading-tight"
                    >
                      {waitingRef.speaking} Second
                    </h2>
                  </div>
                </div>
                <div className="card-footer bg-[#fff] flex align-center justify-between pt-[18px] md:pt-10  gap-4">
                  <button
                    disabled={started ? false : true}
                    color="#52e8a0"
                    className={`w-auto border-[2px] border-[#52e8a0] text-[14px] rounded  font-[400] cursor-pointer py-[10px]`}
                    // onClick={async () => {
                    //   if (part === 2) {
                    //     let repeat = async () => {
                    //       for (
                    //         let index = 0;
                    //         index < data.part_2.questions.length;
                    //         index++
                    //       ) {
                    //         await SpeakFunc(
                    //           data.part_2.questions[index].question_id
                    //         );
                    //         await new Promise((resolve) => {
                    //           setTimeout(() => {
                    //             resolve();
                    //           }, 100);
                    //         });
                    //       }
                    //     };
                    //     repeat();
                    //     return;
                    //   }
                    //   await SpeakFunc(currentRef.id);
                    // }}

                    onClick={() => {
                      if (part === 2) {
                        let repeat = async () => {
                          for (
                            let index = 0;
                            index < data.part_2.questions.length;
                            index++
                          ) {
                            console.log(index);
                            if (index == 1) {
                              await SpeakFunc(
                                REACT_APP_API_CORE +
                                  "/api/speech/" +
                                  ready.speaker +
                                  "/say.mp3"
                              );
                            }
                            await SpeakFunc(
                              REACT_APP_API_CORE +
                                "/api/speech/" +
                                ready.speaker +
                                "/" +
                                data.part_2.questions[index].question_id +
                                ".mp3"
                            );

                            await new Promise((resolve) => {
                              setTimeout(() => {
                                resolve();
                              }, 100);
                            });
                          }
                        };
                        repeat();
                        return;
                      }
                      let repeats = async () => {
                        await SpeakFunc(
                          REACT_APP_API_CORE +
                            "/api/speech/" +
                            ready.speaker +
                            "/" +
                            currentRef.id +
                            ".mp3"
                        );
                      };

                      repeats();
                    }}
                  >
                    Repeat Question
                  </button>
                  {started ? (
                    <></>
                  ) : (
                    <button
                      disabled={data && !started ? false : true}
                      onClick={() => {
                        if (started) return;
                        handleStart();
                      }}
                      className={`cursor-pointer w-auto border-[2px] border-[#3498db] text-[14px] rounded hover:bg-[#3498db] hover:text-[#fff] font-[400] flex flex-row gap-[8px] items-center justify-center py-[10px] `}
                    >
                      Start
                    </button>
                  )}

                  <button
                    disabled={started ? false : true}
                    color="#52e8a0"
                    onClick={finishAfterThis}
                    className={
                      ` w-auto border-[2px] border-[#52e8a0] text-[14px] rounded hover:bg-[#ffff] cursor-pointer  font-[400] flex flex-row gap-[8px] items-center justify-center py-[10px] ` +
                      (finishAfter ? "border-none" : "")
                    }
                  >
                    finish
                  </button>
                  {data?.part_1 && data?.part_2 && data?.part_3 ? (
                    <MuiModal
                      finished={finished}
                      urlVal={urlVal}
                      timeLine={timeLine}
                      questions_id={questions_id}
                      data={{
                        part1: { ...data?.part_1 },
                        part2: { ...data?.part_2 },
                        part3: [...data?.part_3],
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
});

export default Speaking;
