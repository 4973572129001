// src/store/useValidationStore.js
import create from "zustand";

const useOptionstore = create((set) => ({
  options: {
    speaker: "adam",
    isReady: false,
  },
  setOptions: (value) => set({ ...value }),
}));

export { useOptionstore };
