import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./Blog.css";
import { Helmet } from "react-helmet";

export default function Blog() {
  let [data, setData] = useState(null);
  let [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const { id } = useParams();
  const PostArticle = async (type) => {
    try {
      if (type.length === 0) {
        console.log("type is required");
        return;
      }
      setLoading(true);
      let { data } = await axios.get(
        "https://calmness28.jprq.app/api/admin/blog/" +
          id.split("-")[id.split("-").length - 1]
      );

      console.log(data);
      if (data.success) {
        setData(data);
        // navigate("/posts");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    PostArticle("publish");
  }, []);
  return (
    <div>
      <Helmet>
        <title>{data?.post?.title}</title>
        <meta name="description" content={data?.post?.description} />
        <meta name="keywords" content={data?.post?.title} />
        <meta property="og:title" content={data?.post?.title} />
        <meta property="og:image" content={data?.post?.image_url} />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="refresh" content="180" />

        <link
          rel="canonical"
          href={`https://www.ispeak.uz/blog/${data?.post?.slug}`}
        />
      </Helmet>
      <div className="z-10 sticky top-0 bg-[#F3F3F3]  flex flex-row justify-between max-w-[1000px] w-[100%] m-auto mb-[20px] p-2">
        <button
          onClick={() => {
            navigate("/");
          }}
          className="flex flex-row items-center justify-center transition-all hover:gap-[14px] gap-[10px] px-[15px] md:px-[20px] rounded-[40px] py-[10px] md:py-[8px] bg-[#2222220d]"
        >
          <svg
            className="back-button text-[14px] w-[12px] md:w-[16px] mg-r-8 pull-this tran-03s vert-align-md"
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Back</title>
            <g
              id="Page-1"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="Post-Editor-(empty)"
                transform="translate(-213.000000, -33.000000)"
              >
                <g id="Group-13">
                  <g id="Group-6" transform="translate(209.000000, 28.000000)">
                    <g
                      id="ic-arrow-forward-36px"
                      transform="translate(12.000000, 13.000000) rotate(-180.000000) translate(-12.000000, -13.000000) translate(0.000000, 1.000000)"
                    >
                      <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                      <polygon
                        id="Path"
                        fill="#000000"
                        points="12 4 10.5866667 5.41333333 16.1733333 11 4 11 4 13 16.1733333 13 10.5866667 18.5866667 12 20 20 12"
                      ></polygon>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <span className="hidden md:flex "> back</span>
        </button>

        <div className="flex flex-row items-center gap-[10px]"></div>
      </div>
      <div className="max-w-[680px] w-[90%] m-auto">
        {loading ? (
          <div className="loading-state">
            <div className="flex flex-col gap-[14px] items-center justify-center">
              <p className="font-[600]">Loading...</p>
              <div className="loader" />
            </div>
          </div>
        ) : (
          <></>
        )}
        {data?.post ? (
          <div className="w-[100%] flex flex-col items-center justify-cewnter mt-[50px] mb-[20px]">
            <h1 className="text-[28px] text-[#242424]  my-[30px] md:text-[32px] font-bold">
              {data.post?.title}
            </h1>

            <div>
              <img
                src={`https://ucarecdn.com/${data?.post?.image_url}/-/preview/640x640/`}
                alt=""
              />
            </div>
            <h2 className="text-[18px] roboto-medium leading-[32px] text-[#242424]   md:text-[20px] font-medium">
              {data.post?.description}
            </h2>
            <ReactQuill
              value={data.post.content}
              // onChange={setData}
              className="w-[100%] bg-[#F3F3F3]"
              readOnly={true}
              theme="snow" // or "bubble"
              modules={{ toolbar: false }} // Disable toolbar
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
