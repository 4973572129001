import { create } from "zustand";

const useStore = create((set) => ({
  urlVal: null,
  setUrlVal: (urlVal) => set({ urlVal }),
}));
const useStoreLoading = create((set) => ({
  loading: false,
  setLoading: (loading) => set({ loading }),
}));

export { useStore, useStoreLoading };
