import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { add } from "../../../redux/features/question/user";
import { Backdrop, CircularProgress } from "@mui/material";
import "./FormName.css";
import { startRecording, stopRecording } from "../record/Recording";
import loadingTestAnim from "../../../assets/loadingTestAnim.json";
import Lottie from "lottie-react";
import axios from "axios";
import SuccessAnim from "../../../assets/SuccessAnim.json";
import microphoneAnim from "../../../assets/microphoneAnim.json";
import recAnimation from "../../../assets/recAnimation.json";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import { toast } from "react-toastify";
import { SpeakFunc } from "../speakFunc/speakFunc";
import { useNavigate } from "react-router-dom";
import Person2Icon from "@mui/icons-material/Person2";
import PauseOutlinedIcon from "@mui/icons-material/PauseOutlined";
import { useOptionstore } from "../../../redux/Stores";

export default function FormName({ setReady, ready }) {
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  let [isChecking, setIsChecking] = useState(false);
  let [start, setStart] = useState(false);
  const { options, setOptions } = useOptionstore();
  let [isRecording, setIsRecording] = useState({
    isRecording: false,
    isSupported: true,
    isRejected: false,
  });
  let [urlVal, setUrlVal] = useState(null);
  let [checkSpeaked, setCheckSpeaked] = useState(false);
  let [tryAgain, setTryAgain] = useState(false);

  const lottieRef = useRef();
  let navigate = useNavigate();

  const audio1Ref = useRef(
    new Audio("https://calmness28.jprq.app/api/speech/adam/say.mp3")
  );
  const audio2Ref = useRef(
    new Audio("https://calmness28.jprq.app/api/speech/bella/say.mp3")
  );

  let [speakers, setSpeakers] = useState([
    {
      id: 112,
      name: "Adam",
      country: "American narrator",
      speakerId: "Hello Adam.mp3",
      url: "https://calmness28.jprq.app/api/speech/adam/111.mp3",
      audio: new Audio("https://calmness28.jprq.app/api/speech/adam/111.mp3"),
      isActive: true,
      isPlaying: false,
    },
    {
      id: 111,
      name: "Bella",
      country: "American Los angeles",
      url: "https://calmness28.jprq.app/api/speech/bella/111.mp3",
      audio: new Audio("https://calmness28.jprq.app/api/speech/bella/111.mp3"),
      isActive: false,
      isPlaying: false,
    },
  ]);

  // checking recording is working file or no
  useEffect(() => {
    if (!isRecording.isSupported) {
      alert(
        "Your browser does not support our programm! \nUpdate your browser!"
      );
      setStart(false);
      setOptions({ ...options, isReady: false });
    }

    if (isRecording.isRejected) {
      alert(
        "you did not allow us to reacord your voice!  or  there is problem with recording!\n if you do not allow us to record your voice you can not start exam."
      );
      setStart(false);
      setOptions({ ...options, isReady: false });
    }

    if (isRecording.isRecording) {
      toast("Recording started!");
    }
  }, [isRecording]);

  // recorded voice turning into blob url
  useEffect(() => {
    try {
      console.log(urlVal);
      if (urlVal) {
        setUrlVal(URL.createObjectURL(urlVal));
      }
    } catch (error) {
      console.log(error);
    }
  }, [urlVal]);

  let audioContext;
  let analyser;
  let microphone;
  let scriptProcessor;
  let stream;
  let additionCheck;
  // ckeck voice recording function
  let checkRecording = () => {
    try {
      toast("Clicked!");
      // Check if the browser supports getUserMedia
      if (!navigator.mediaDevices && !navigator.mediaDevices.getUserMedia) {
        toast("The Browser does not support us.NPlease try on browser!");
        return;
      }

      if (!isChecking) {
        // Request permission to access the microphone
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then(function (stream) {
            audioContext = new AudioContext();
            analyser = audioContext.createAnalyser();
            microphone = audioContext.createMediaStreamSource(stream);
            scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);

            analyser.smoothingTimeConstant = 0.8;
            analyser.fftSize = 1024;

            microphone.connect(analyser);
            analyser.connect(scriptProcessor);
            scriptProcessor.connect(audioContext.destination);
            scriptProcessor.onaudioprocess = function () {
              const array = new Uint8Array(analyser.frequencyBinCount);
              analyser.getByteFrequencyData(array);
              const arraySum = array.reduce((a, value) => a + value, 0);
              const average = arraySum / array.length;

              if (Math.round(average) > 25) {
                // console.log(Math.round(average));
                setCheckSpeaked(true);
                additionCheck = true;
              }
              // colorPids(average);
            };

            // User granted permission
            lottieRef.current.play();
            setIsChecking(true);
            setStart(false);
            console.log("Permission granted");
            startRecording(isRecording, setIsRecording);

            setTimeout(() => {
              if (!additionCheck) {
                lottieRef.current.pause();
                setTryAgain(true);
                setCheckSpeaked(false);
                setIsChecking(false);
                setStart(false);
                stopRecording(setUrlVal);
              }
            }, 7000);
          })
          .catch(function (error) {
            toast(
              "Microphone Permission denied!\nPlease allow recording voice from browser settings"
            );
            console.error("Permission denied or error: 1", error);
          });
      }

      if (isChecking && checkSpeaked) {
        // Ensure the page has access to the devices
        // await navigator.mediaDevices.getUserMedia({ audio: true });

        // Enumerate the devices
        // const devices = await navigator.mediaDevices.enumerateDevices();
        // const mics = devices.filter((device) => device.kind === "audioinput");
        // console.log(mics);
        // console.log(devices);
        if (scriptProcessor) {
          scriptProcessor.disconnect();
          scriptProcessor.onaudioprocess = null; // Remove the event handler
        }
        if (analyser) {
          analyser.disconnect();
        }
        if (microphone) {
          microphone.disconnect();
        }
        if (audioContext) {
          audioContext.close();
        }
        stopRecording(setUrlVal);
        setIsChecking(false);
        setStart(true);

        lottieRef.current.pause();
      } else {
        toast("Speak loudly or\nmake sure your microphone is working fine!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {"succeeded" ? (
        <div className="min-h-screen flex items-center justify-center">
          <div className="max-w-[1000px] w-full bg-white p-8 shadow-md rounded-md flex flex-col gap-[30px] items-center ">
            <div className="flex flex-col md:flex-row justify-between w-[100%]">
              <div className="md:w-[40%] bg-white rounded-xl border border-gray-100 pr-0 sm:pr-1 p-1 sm:p-1 mt-1 sm:mt-0">
                <h2 className="text-2xl font-bold mb-2">
                  Check your microphone here
                </h2>
                <span
                  className="block font-serif text-xl font-normal text-black mb-2"
                  role="none"
                >
                  Select voice
                </span>
                <div className="flex sm:flex-col  flex-row gap-[10px]">
                  {speakers.map((el, i) => {
                    // if (el.isActive) {
                    //   setOptions({ ...options, speaker: el });
                    // }
                    return (
                      <div
                        className={
                          "relative  flex  sm:flex-row flex-col items-center border-2 p-3 mb-1 border rounded-xl cursor-pointer select-none justify-between overflow-hidden  bg-coolGray-100 border-gray-100 hover:bg-coolGray-200 " +
                          (el.isActive
                            ? "bg-coolGray-200 border-gray-600 "
                            : "")
                        }
                        id="headlessui-radiogroup-option-:r4:"
                        role="radio"
                        aria-checked="false"
                        tabIndex="-1"
                        data-headlessui-state=""
                        onClick={() => {
                          try {
                            // SpeakFunc(el.url);
                            setReady({
                              ready: false,
                              speaker: el.name.toLocaleLowerCase(),
                            });
                            setSpeakers([
                              ...speakers.map((item) => {
                                if (item.isActive) {
                                  item.isActive = false;
                                }

                                if (item.id == el.id) {
                                  setOptions({ ...options, speaker: el.name });
                                  item.isActive = true;
                                }

                                return item;
                              }),
                            ]);

                            if (i == 0) {
                              // Pause audio2 if it's playing
                              if (!audio2Ref.current.paused) {
                                audio2Ref.current.pause();
                              }
                              // Play audio1
                              audio1Ref.current.currentTime = 0;
                              audio1Ref.current.play();
                            }

                            if (i == 1) {
                              // Pause audio2 if it's playing
                              if (!audio1Ref.current.paused) {
                                audio1Ref.current.pause();
                              }
                              // Play audio1
                              audio2Ref.current.currentTime = 0;
                              audio2Ref.current.play();
                            }
                          } catch (error) {
                            console.log(error.message);
                          }
                        }}
                      >
                        <div className="flex sm:flex-row flex-col text-center sm:text-left gap-[10px]  items-center justify-center">
                          {/* <img
                            alt="Adam"
                            loading="lazy"
                            width="1024"
                            height="1024"
                            decoding="async"
                            data-nimg="1"
                            className="w-16 rounded-lg smmr-3 "
                            src="https://ucarecdn.com/ea4f57b8-a48d-44d2-b18b-e8db8e6aef8b/-/preview/640x640/"
                          /> */}
                          <div className="flex flex-col">
                            <span className="block font-serif text-xl font-semibold text-black">
                              {el.name}
                            </span>
                            <span className="block font-serif text-sm font-normal text-gray-700 whitespace-nowrap">
                              American narrator{" "}
                            </span>
                          </div>
                        </div>
                        {i === 0 ? (
                          audio1Ref.current.paused ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                              className="hidden sm:flex text-black w-11 hover:text-gray-700 cursor-pointer"
                            >
                              <path
                                fillRule="evenodd"
                                d="M2 10a8 8 0 1116 0 8 8 0 01-16 0zm6.39-2.908a.75.75 0 01.766.027l3.5 2.25a.75.75 0 010 1.262l-3.5 2.25A.75.75 0 018 12.25v-4.5a.75.75 0 01.39-.658z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                          ) : (
                            <div className="hidden sm:flex text-black w-11 hover:text-gray-700 cursor-pointer">
                              <PauseOutlinedIcon />
                            </div>
                          )
                        ) : (
                          <></>
                        )}
                        {i === 1 ? (
                          audio2Ref.current.paused ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                              className="hidden sm:flex text-black w-11 hover:text-gray-700 cursor-pointer"
                            >
                              <path
                                fillRule="evenodd"
                                d="M2 10a8 8 0 1116 0 8 8 0 01-16 0zm6.39-2.908a.75.75 0 01.766.027l3.5 2.25a.75.75 0 010 1.262l-3.5 2.25A.75.75 0 018 12.25v-4.5a.75.75 0 01.39-.658z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                          ) : (
                            <div className="hidden sm:flex text-black w-11 hover:text-gray-700 cursor-pointer">
                              <PauseOutlinedIcon />
                            </div>
                          )
                        ) : (
                          <></>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="flex flex-col items-center justify-start">
                {/* microphoneAnim */}
                {/* <div className="w-[100px] h-[100px]">
                  <Lottie animationData={microphoneAnim} loop={false} />
                </div> */}
                <div className="w-[150px] ">
                  <Lottie
                    lottieRef={lottieRef}
                    animationData={recAnimation}
                    autoplay={false}
                  />
                </div>
                <span
                  className="block w-[80%] text-center text-[#626976] font-serif text-md font-normal text-black mb-2"
                  role="none"
                >
                  Check your microphone here to be sure everything is working
                  fine!
                </span>

                {urlVal && !tryAgain ? (
                  <audio src={urlVal} autoPlay controls />
                ) : (
                  <></>
                )}
                <div className="flex flex-row gap-[10px]">
                  {!start && !tryAgain ? (
                    <button
                      onClick={checkRecording}
                      className={
                        checkSpeaked
                          ? "flex items-center gap-[10px] mt-[30px] btn btn-primary bg-[#03AED2] text-[#fff] py-3 px-4 rounded-xl btn-xl btn-normal"
                          : "flex items-center gap-[10px] mt-[30px] btn btn-primary bg-[#312b41] text-[#fff] py-3 px-4 rounded-xl btn-xl btn-normal"
                      }
                      type="button"
                    >
                      {isChecking && !tryAgain ? "Recording..." : "lets check"}
                      {/* <Lottie animationData={microphoneAnim} loop={true} /> */}
                      <KeyboardVoiceIcon />
                    </button>
                  ) : (
                    <></>
                  )}

                  {start && !tryAgain ? (
                    <button
                      onClick={() => {
                        console.log("hell");
                        setReady({ ...ready, ready: true });
                        setOptions({ ...options, isReady: true });
                      }}
                      className="flex text-[#000]  items-center gap-[10px] mt-[30px] border-2 border-[#bfbfbf]  bg-[#fff] py-3 px-4 rounded-xl btn-xl "
                    >
                      Start Exam
                      {/* <Lottie animationData={microphoneAnim} loop={true} /> */}
                      <Lottie
                        style={{
                          width: "30px",
                        }}
                        loop={false}
                        animationData={SuccessAnim}
                        autoplay={true}
                      />
                    </button>
                  ) : (
                    <></>
                  )}

                  {tryAgain ? (
                    <button
                      className="flex items-center gap-[10px] mt-[30px] btn btn-primary bg-[#000] text-[#fff] py-3 px-4 rounded-xl btn-xl btn-normal"
                      onClick={() => {
                        setUrlVal(null);
                        setTryAgain(false);
                      }}
                    >
                      Try again
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="loading-state">
          <div className="loader" />
        </div>
      )}
    </>
  );
}
