import { toast } from "react-toastify";
export async function SpeakFunc(url) {
  try {
    return new Promise((resolve, reject) => {
      var audio = new Audio(url);
      audio.onended = resolve;
      audio.onerror = reject;
      audio.play();
    });
  } catch (error) {
    console.log(error.message);
  }
}
