import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const fetchCache = "force-no-store";
export const revalidate = 0;

const initialState = {
  status: "",
};

export const fetchData = createAsyncThunk("data/fetchData", async (id) => {
  try {
    const { data } = await axios.get(
      "https://my-test-795d.onrender.com/api/student/" + id,
      {
        headers: {
          "Cache-Control": "no-cache",
        },
      }
    ); // Replace with your API call
    console.log(data);
    return data;
  } catch (error) {
    return error.response.data;
  }
});

const questionsSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {
    loading(state, actions) {
      return {
        ...state,
        status: "loading",
      };
    },
    editData(state, actions) {
      return {
        ...state,
        partOne: actions.payload.data.tests[0],
        partTwo: actions.payload.data.tests[1],
      };
    },
    loaded(state, actions) {
      return {
        ...state,
        status: "",
      };
    },
    removePartOneQuest(state, actions) {
      let [, ...arr] = state.partOne.questions;
      return {
        ...state,
        partOne: {
          ...state.partOne,
          questions: [...arr],
        },
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchData.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        if (
          action.payload.message === "Questions not found" ||
          action.payload.statusCode !== 200
        ) {
          state.partOne = null;
          state.partTwo = null;
          state.status = "succeeded";
        } else {
          state.partOne = action.payload.data.tests[0];
          state.partTwo = action.payload.data.tests[1];
          state.status = "succeeded";
        }
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

// export const getQuestError = (initialState) => initialState.error;
// export const getQuestStatus = (initialState) => initialState.status;
// export const getAll = (state) => state;

export const { removePartOneQuest, loading, loaded, editData } =
  questionsSlice.actions;

export default questionsSlice.reducer;
