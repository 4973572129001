import { createSlice } from "@reduxjs/toolkit";

let initialState = {};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    add(state, actions) {
      return { ...state, ...actions.payload };
    },
  },
});

export const { add } = userSlice.actions;

export default userSlice.reducer;
