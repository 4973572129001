import { Avatar } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.svg";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { SiTelegram } from "react-icons/si";

export default function Footer() {
  return (
    <footer className="bg-[#f5f6fb]  py-[20px]">
      <header className=" z-10 bg-[#f5f6fb] mb-[20px] ">
        <div className="max-w-[1128px] m-auto w-[90%]  bg-[#f5f6fb]">
          <div className="text-[#000] bg-[#f5f6fb] font-[500] flex flex-row items-center justify-between">
            <nav>
              <ul className="flex font-[600]  flex-row items-center gap-[20px] text-[17px] leading-[20px]">
                <li className=" md:flex cursor-pointer hover:text-[#000]">
                  <Link href="hello" className="relative">
                    {/* #72718a */}
                    <img
                      src={logo}
                      alt="our bussines logo"
                      className=" top-0"
                      width={70}
                      height={80}
                    />
                  </Link>
                </li>

                <li className=" hidden md:flex cursor-pointer hover:text-[#0053f3]">
                  <Link to="https://t.me/devsideuz">Developers</Link>
                </li>

                {/* <li className="hidden md:flex cursor-pointer hover:text-[#0053f3]">
                  <Link to="/blog">Blog</Link>
                </li> */}

                {/* <li className=" md:hidden cursor-pointer">
                  <MobileHeader />
                </li> */}
              </ul>
            </nav>
            <div className="   cursor-pointer">
              {/* <Avatar sx={{ width: "35px", height: "35px" }}>{name[0]}</Avatar> */}
              <Link
                to={"https://t.me/creators_team_bot"}
                className="text-[24px] md:text-[32px]"
              >
                <SiTelegram />
              </Link>
            </div>
          </div>
        </div>
      </header>
      <div className="max-w-[1128px] m-auto w-[98%]  border-t-[1px] pt-[16px] border-t-[#728a9666]">
        <div className="text-[#728a96] flex flex-col md:flex-row items-center gap-[20px]  justify-center md:justify-between">
          <Link href="https://t.me/multilevel_vs_ielts">ⓒ Copy right 2024</Link>

          <nav>
            <ul className="flex flex-col md:flex-row items-center justify-center gap-[20px]">
              <li className="hover:text-[#0053f3]">
                <Link
                  className="flex gap-[5px]"
                  to="https://t.me/multilevel_vs_ielts"
                >
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="styles_commentsIcon__IGRzH"
                  >
                    <path
                      d="M12.8044 14.659C11.958 15.0159 11.011 15.2157 10.0114 15.2157C6.41527 15.2157 3.5 12.6303 3.5 9.44118C3.5 6.25202 6.41527 3.66669 10.0114 3.66669C13.6076 3.66669 16.5229 6.25202 16.5229 9.44118C16.5229 10.732 16.0453 11.924 15.2382 12.8856"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M12.855 14.663L15.6807 16.1523L15.1609 12.9676"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  Contact
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </footer>
  );
}
