import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export default function ArticlesCard({ image_url, title, desc, time, id }) {
  return (
    <Link to={"/blog/" + id}>
      <div className="max-w-[350px] flex flex-col items-center hover:scale-105 transition  bg-[#fff] rounded-[24px] overflow-hidden ">
        <img
          width={"100%"}
          className="shrink-0 mt-[10px] w-[95%]  rounded-xl h-[200px] max-w-full object-cover hover:scale-105 transition "
          src={`https://ucarecdn.com/${image_url}/-/preview/640x640/`}
          alt=""
        />
        <div className="px-[24px] pb-[24px]">
          <p className="text-[#728a96] text-[14px] mb-[6px] mt-[24px]">
            {time}
          </p>
          <h2 className="font-[600] text-[20px] text-[#00] leading-[26px]">
            {title.length > 20 ? `${title.slice(0, 20)}...` : title}
          </h2>
          <p className="font-[400] text-[17px] text-[#00] leading-[26px]">
            {desc.length > 50 ? `${desc.slice(0, 50)}...` : desc}
          </p>
        </div>
      </div>
    </Link>
  );
}
