import { Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.svg";
import MobileHeader from "./MobileHeader";
import axios from "axios";
import useValidationStore from "../../redux/ValidationStore";

export default function Heaader() {
  let [name, setName] = useState("");
  const { isValid, setIsValid } = useValidationStore();

  let REACT_APP_API_CORE = "https://calmness28.jprq.app";
  useEffect(() => {
    let name = localStorage.getItem("name");
    let token = localStorage.getItem("token");
    let getData = async () => {
      try {
        let { data } = await axios.get(
          REACT_APP_API_CORE + "/api/validate_jwt",
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        console.log("data");
        console.log(data);
        if (!data.error) setIsValid(true);
        setName(name);
      } catch (error) {
        console.log(error.message);
        console.log(error.status);
        setIsValid(false);
      }
    };
    if (token) getData();
  }, []);
  return (
    <header className=" z-10 bg-[#f5f6fb] sticky top-0 ">
      <div className="px-[16px] border-b-[1px] border-b-[#728a9666] md:border-none m-auto max-w-[1128px] w-[100%] bg-[#f5f6fb] ">
        <div className="text-[#000] bg-[#f5f6fb] font-[500] flex flex-row items-center justify-between">
          <nav>
            <ul className="flex font-[600]  flex-row items-center gap-[20px] text-[17px] leading-[20px]">
              <li className=" md:flex cursor-pointer hover:text-[#000]">
                <Link href="hello" className="relative">
                  {/* #72718a */}
                  <img
                    src={logo}
                    alt="our bussines logo"
                    className=" top-0"
                    width={70}
                    height={80}
                  />
                </Link>
              </li>

              <li className="hidden md:flex cursor-pointer hover:text-[#0053f3]">
                <Link to="https://t.me/devsideuz">Developers</Link>
              </li>
              {/* 
              <li className="hidden md:flex cursor-pointer hover:text-[#0053f3]">
                <Link to="/blog">Blog</Link>
              </li> */}
            </ul>
          </nav>
          <div className=" md:flex  cursor-pointer">
            {isValid ? (
              <Avatar sx={{ width: "35px", height: "35px" }}>{name[0]}</Avatar>
            ) : (
              // <button className="flex flex-row gap-[4px] py-[12px] px-[24px] bg-[#0053f3] text-[#fff] text-[17px] rounded-3xl hover:scale-110 transition hover:font-[600]">
              //   Register now
              // </button>
              <Link
                to={"/"}
                className="py-[12px] px-[24px] bg-[#0053f3] text-[#fff] text-[14px] md:text-[17px] rounded-3xl hover:scale-110 transition hover:font-[600]"
              >
                Register now
              </Link>
            )}
          </div>
        </div>
        <div className="md:hidden py-[8px] ">
          <nav>
            <ul className="flex font-[600]  flex-row items-center gap-[20px] text-[12px] leading-[20px]">
              <li className=" md:flex cursor-pointer hover:text-[#0053f3]">
                <Link to="https://t.me/devsideuz">Developers</Link>
              </li>
              {/* <li className=" md:flex cursor-pointer hover:text-[#0053f3]">
                <Link to="https://t.me/multilevel_vs_ielts">About</Link>
              </li>
              <li className=" md:flex cursor-pointer hover:text-[#0053f3]">
                <Link to="/blog">Blog</Link>
              </li> */}
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}
